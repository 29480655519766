import React from "react";
import { ContactWrapper } from "./BenefitsElements";

import ScrollAnimation from "react-animate-on-scroll";
function Benefits() {
  return (
    <ContactWrapper id="benefits">
      <div className="Container">
        <ScrollAnimation animateIn="fadeIn">
          <section class="section section--gray">
            <div class="section-header">
              <h2 class="section-heading-eyebrow">Benefits</h2>
              <h3 class="section-heading">
                Your perfect <br />
                MERN partner.
              </h3>
            </div>
            <div class="container--large">
              <div class="w-layout-grid intro-grid">
                <div id="node-theme-developer" class="intro-card">
                  <h4 class="intro-card-heading">
                    MERN Stack Developer at the intersection of design and code.
                    <br />
                  </h4>
                  <p class="intro-card-description">
                    Celebrated for its extensive proficiency in MERN Stack
                    development, Tineth has transformed the landscape of web
                    development with cutting-edge, full stack applications.
                    Recognized worldwide, this forward-thinking person
                    integrates MongoDB, Express.js, React and Node.js to create
                    exceptional user experiences. As a leader in the tech arena,
                    Tineth consistently establishes new standards in the
                    software industry.
                  </p>
                </div>
                <div class="w-layout-grid stats-grid">
                  <div id="node-stat" class="intro-card intro-card--stats-cell">
                    <div class="stats-figure-wrap stats-figure-wrap--customers">
                      <div class="intro-stats-figure">15</div>
                      <div class="intro-stats-symbol intro-stats-symbol--plus">
                        +
                      </div>
                    </div>
                    <div class="stats-descriptoin">Happy Clients</div>
                  </div>
                  <div class="intro-card intro-card--stats-cell">
                    <div class="stats-figure-wrap stats-figure-wrap--years">
                      <div class="intro-stats-figure">3</div>
                      <div class="intro-stats-symbol intro-stats-symbol--years">
                        Years
                      </div>
                    </div>
                    <div class="stats-descriptoin">Experience</div>
                  </div>
                  <div class="intro-card intro-card--stats-cell">
                    <div class="stats-figure-wrap stats-figure-wrap--projects">
                      <div class="intro-stats-figure">20</div>
                      <div class="intro-stats-symbol intro-stats-symbol--plus">
                        +
                      </div>
                    </div>
                    <div class="stats-descriptoin">MERN Projects</div>
                  </div>
                  <div class="intro-card intro-card--stats-cell">
                    <div class="stats-figure-wrap stats-figure-wrap--passion">
                      <div class="intro-stats-figure">100</div>
                      <div class="intro-stats-symbol intro-stats-symbol--percent">
                        %
                      </div>
                    </div>
                    <div class="stats-descriptoin">Passion</div>
                  </div>
                </div>
                <div id="node-speed" class="intro-card intro-card--performance">
                  <h4 class="intro-card-heading">Lightning-fast load times.</h4>
                  <img
                    src="speed.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 86vw, (max-width: 767px) 79vw, 600px"
                    alt="Screenshot of a Perfect website showing a performance score of 100 points"
                    class="intro-card-performance-img"
                  />
                </div>
                <div
                  id="node-integrations"
                  class="intro-card intro-card--integrations"
                >
                  <img
                    src="integrations.webp"
                    loading="lazy"
                    sizes="(max-width: 479px) 90vw, (max-width: 767px) 92vw, (max-width: 991px) 94vw, (max-width: 1416px) 96vw, 1360px"
                    alt="gh integrations"
                    class="intro-card-integrations-img"
                  />
                  <h4 class="intro-card-heading">
                    Custom <br />
                    Integrations.
                  </h4>
                </div>
              </div>
              <div class="w-layout-grid intro-grid intro-grid--variant">
                <div
                  id="node-pixel-perfect"
                  class="intro-card intro-card--pixel"
                >
                  <h4 class="intro-card-heading">
                    <span class="intro-card-pixel-perfect">Pixel-Perfect</span>
                    <br />
                    Development.
                  </h4>
                </div>
                <div
                  id="node-responsive-design"
                  class="intro-card intro-card--responsive"
                >
                  <h4 class="intro-card-heading">Responsive Design.</h4>
                  <img
                    src="responsive-design.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 78vw, (max-width: 767px) 83vw, (max-width: 991px) 81vw, 86vw"
                    alt="A gh website opened on desktop, tablet and mobile"
                    class="intro-cad-responsive-img"
                  />
                </div>
              </div>
              <div class="w-layout-grid intro-grid">
                <div
                  id="node-clean-code"
                  class="intro-card intro-card--tiny cc-code"
                >
                  <h4 class="intro-card-heading intro-card-heading--tiny">
                    Clean code
                  </h4>
                  <div class="intro-card-code-wrap">
                    <div>
                      <span class="code-color">&lt;ul&gt;</span>
                    </div>
                    <div class="intro-card-code-indented">
                      <span class="code-color">&lt;li&gt;</span>Declarative
                      React.
                      <span class="code-color">&lt;/li&gt;</span>
                    </div>
                    <div class="intro-card-code-indented">
                      <span class="code-color">&lt;li&gt;</span>Class naming
                      system.
                      <span class="code-color">&lt;/li&gt;</span>
                    </div>
                    <div class="intro-card-code-indented">
                      <span class="code-color">&lt;li&gt;</span>Optimized for
                      performance.<span class="code-color">&lt;/li&gt;</span>
                    </div>
                    <div>
                      <span class="code-color">&lt;/ul&gt;</span>
                    </div>
                  </div>
                </div>
                <div
                  id="node-duration"
                  data-w-id="10a1a4da-5d2d-cb83-41b6-3638d47e2759"
                  class="intro-card intro-card--tiny cc-dev-speed"
                >
                  <h4 class="intro-card-heading intro-card-heading--tiny cc-dev-speed">
                    Reliable hosting
                  </h4>
                  <div class="intro-card-dev-speed-wrap">
                    <img
                      src="/cloud.gif"
                      loading="lazy"
                      alt="Reliable circle"
                      class="hosting-cad-img"
                    />
                  </div>
                </div>
                <div id="node-8-reasons" class="reaons-banner-wrap">
                  <a
                    href="/reasons"
                    class="intro-card intro-card--reasons-banner lift w-inline-block"
                  >
                    <div class="reasons-banner-heading">
                      10 reasons your website should run on MERN.
                    </div>
                    <div class="cta-link cc-reasons-banner">
                      <div class="cta-link-text">See reasons</div>
                      <div class="cta-link-icon w-embed">
                        <svg
                          width="100%"
                          height="100%"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          data-reactroot=""
                        >
                          <path
                            stroke-linejoin="round"
                            stroke-linecap="round"
                            stroke-width="3.5"
                            stroke="currentColor"
                            d="M8 20L16 12L8 4"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </ScrollAnimation>
      </div>
    </ContactWrapper>
  );
}

export default Benefits;
