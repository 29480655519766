import React from "react";
import { HeroContainer } from "./HeroElements";

function Hero() {
  return (
    <main>
      <HeroContainer>
        <header class="hero">
          <div class="container container--hero">
            <div class="hero-img-wrap">
              <div class="hero-img-container">
                <img
                  src="/tineth.png"
                  loading="eager"
                  srcset=" "
                  sizes="(min-width: 720px) 720px"
                  alt="Headshot of Tineth Pathirage, a MERN Stack Developer"
                  class="hero-img"
                />
              </div>
              <div id="hero-img-shadow-1" class="hero-img-shadow-wrap">
                <div class="hero-img-shadow"></div>
              </div>
              <div
                id="hero-img-shadow-2"
                class="hero-img-shadow-wrap hero-img-shadow-wrap--intro"
              >
                <div class="hero-img-shadow"></div>
              </div>
            </div>
            <div class="hero__header">
              <h2 class="hero-eyebrow">Tineth Pathirage</h2>
              <div class="hero-heading-ui-wrap">
                <h1 class="hero-heading">Certified MERN Expert.</h1>
              </div>
              <p class="hero-paragraph-mobile">
                Revolutionizing the digital world with MERN dominance.
              </p>
              <a
                href="https://wa.me/+94763113219"
                target="_blank"
                class="cta-link cta-link--hero ix--cta w-inline-block"
                rel="noreferrer"
              >
                <div class="cta-link-text">Start a project request</div>
                <div class="cta-link-icon cta-link-icon--hero w-embed">
                  <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      stroke-width="3.5"
                      stroke="currentColor"
                      d="M8 20L16 12L8 4"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </header>
      </HeroContainer>
    </main>
  );
}

export default Hero;
