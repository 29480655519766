import React from "react";
import { ContactWrapper } from "./PrivacyElements";
import ScrollAnimation from "react-animate-on-scroll";
function PrivacyDetails() {
  return (
    <ContactWrapper id="privacy">
      <div className="Privacy-Container">
        <div className="SectionTitle">Privacy Policy</div>
        <div className="BigCard">
          <div className="PrivacyBio">
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>1. An overview of data protection</h1>
            </ScrollAnimation>
            <br /> <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>General information</h2>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                You can easily navigate through the following information to
                learn more about what will happen to your personal data when you
                visit this website. The phrase "personal data" refers to all
                information that can be used to identify you personally. Please
                refer to our Data Protection Declaration, which is attached
                below this copy for more information on the topic of data
                protection.
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>Information we collect</h2>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                We collect information that you provide to us when you use our
                services, such as your name, email address, phone number or any
                other personal data that you voluntarily provide to us. We may
                also collect non-personal information, such as your IP address,
                browser type and operating system for the purposes of analyzing
                website traffic and improving our services.
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>How we use your information</h2>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                We use your personal information to provide you with the
                services you have requested, such as responding to your
                inquiries, processing your payments or providing support. We may
                also use your information to improve our services and to
                communicate with you about new products or services that may be
                of interest to you.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>2. Hosting</h1>
            </ScrollAnimation>
            <br /> <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>External hosting</h2>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                A third-party service provider (host) is in charge of hosting
                this website. On the servers of the host, personal information
                gathered on this website is kept. IP addresses, contact
                requests, metadata, communications, contract information,
                contact information, names, web page access and other data
                generated by a website are examples of this, although they are
                not restricted to them.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>3. General information and mandatory information</h1>
            </ScrollAnimation>
            <br /> <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>Data protection</h2>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                The owners of this website and the content it contains are
                committed about protecting your personal information. As a
                result, we treat your personal information as confidential
                information and in accordance with this Data Protection
                Declaration and all applicable legal data protection
                requirements.
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Every time you use our website, several types of personal data
                will be gathered. Information that can be used to identify you
                personally is considered personal data. This Data Protection
                Declaration details the information we gather as well as the
                uses to which it is put. Additionally, it describes how and why
                the data is collected.
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                We hereby notify you that there may be security flaws associated
                with the transmission of data through the Internet (i.e.,
                through email conversations). Data cannot be totally secured
                against unauthorized access.
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>Storage duration</h2>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Your personal information will stay with us until the reason it
                was acquired no longer applies, unless a longer retention period
                has been stipulated in this privacy policy. Unless we have other
                legally permissible reasons to store your personal data (like
                tax or commercial law retention periods), your data will be
                deleted if you make a valid request for deletion or withdraw
                your consent to data processing. In the latter case, the
                deletion will take place after these reasons stop being valid.
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>Revocation of your consent to the processing of data</h2>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Only with your express consent are numerous data processing
                transactions feasible. Any consent you have already given us may
                be revoked at any time. This will not affect the legality of any
                data gathering that took place before your withdrawal.
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>SSL and/or TLS encryption</h2>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                This website uses either an SSL or a TLS encryption program for
                security reasons and to safeguard the transmission of
                confidential content, such as purchase orders or inquiries you
                submit to us as the website operator. Both the presence of the
                lock icon in the browser line and the change in the browser's
                address line from "http://" to "https://" are indicators of an
                encrypted connection.
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Data you send to us cannot be read by outside parties if SSL or
                TLS encryption is enabled.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>4. Recording of data on this website</h1>
            </ScrollAnimation>
            <br /> <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>Server log files</h2>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                The owner of this website and the pages that make up it
                automatically gathers and keeps data in files known as server
                logs, which your browser automatically sends to us. The details
                consist of:
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                <ul className="dotted-list">
                  <li>The type and version of browser used</li>
                  <li>The used operating system</li>
                  <li>Referrer URL</li>
                  <li>The hostname of the accessing computer</li>
                  <li>The time of the server inquiry</li>
                  <li>The IP address</li>
                </ul>
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>This data is not merged with other data sources.</p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>5. Disclosure of information</h1>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                We do not sell or rent your personal information to third
                parties. However, we may share your information with our trusted
                partners or service providers who help us provide our services
                to you, such as our payment processor or email marketing
                platform. We may also disclose your information if required by
                law or in response to a valid request from law enforcement.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>6. Security of information</h1>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                We take reasonable measures to protect your personal information
                from unauthorized access, use or disclosure. We use
                industry-standard security measures such as encryption,
                firewalls and password protection to safeguard your data.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>7. Cookies</h1>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                We may use cookies and other tracking technologies to collect
                information about your browsing behavior on our website. You can
                choose to disable cookies in your browser settings, but please
                note that this may limit your access to some features of our
                website.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>8. Third-Party websites</h1>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Our website may contain links to third-party websites. We are
                not responsible for the privacy practices or content of these
                websites. We encourage you to review the privacy policies of
                these websites before providing any personal information.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>9. Changes to the privacy policy</h1>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Our website may contain links to third-party websites. We are
                not responsible for the privacy practices or content of these
                websites. We encourage you to review the privacy policies of
                these websites before providing any personal information.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>10. Contact Us</h1>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at{" "}
                <a href="mailto:dev@tinethpathirage.com">
                  dev@tinethpathirage.com
                </a>
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>11. Request by e-mail, telephone, or fax</h1>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                If you contact us by phone, fax or email, we will store and
                process your request along with any resulting personal data
                (name, request) in order to fulfill it. Without your permission,
                we do not share these data.
              </p>
            </ScrollAnimation>
            <br />
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h1>12. Analysis tools</h1>
            </ScrollAnimation>
            <br /> <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <h2>Google Analytics (Google Inc.)</h2>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                A online analysis service offered by Google Inc. ("Google") is
                called Google Analytics. Google tracks and analyzes how this
                Application is used, creates reports on its actions and shares
                those findings with other Google services.
              </p>
            </ScrollAnimation>
            <br />
            <ScrollAnimation animateIn="fadeInLeft">
              <p>
                Google may use the information gathered to contextualize and
                tailor the ads on its own network of websites.
              </p>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </ContactWrapper>
  );
}

export default PrivacyDetails;
